li {
  margin-top: 30px;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100px;
  height: 100vh;
  background: #0cc9a9;
  transition: 0.5s;
  overflow: hidden;
  z-index: 9;
}

.navigation:hover {
  width: 350px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
}

.navigation ul li {
  position: relative;

  list-style-type: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  display: flex;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 100px;
  height: 60px;
  line-height: 40px;
  text-align: center;
  font-size: 44px;
  color: rgb(238, 238, 238);
}

.navigation ul li a .title {
  position: relative;
  display: block;
  // padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: start;
  white-space: nowrap;
  color: white;
}

.title {
  font-size: 25px;
}

.line {
  width: 30px;
  height: 3px;
  background: white;
  margin: 5px;
}

.toggle:hover {
  background-color: rgba(255, 255, 255, 0.055);
}

.toggle:before {
  //content: "\f0c9";
  position: absolute;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.toggle:hover:before {
  // content: "\f00d";
}
.hamburger-icon {
}

.navigation-mobile {
  display: none;
}
.hamburger-icon {
  display: none;
}

@media screen and (max-width: 900px) {
  .hamburger-icon {
    color: white;
    background-color: green;
    font-size: 30px;
    z-index: 9999999;
    position: fixed;
    margin-top: 12px;
    margin-left: 12px;
    display: block;
  }

  .navigation {
    left: 0px;
    width: 60%;

    li {
      margin-top: 40px;
    }
    z-index: 9;
  }

  li {
    font-size: 16px;

    padding: 0;
    height: 30px;
  }
  .title {
    color: white;
    font-size: 16px;
  }

  .icon {
    svg {
      font-size: 30px;
    }
  }
}
