.right::before {
  content: "";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10 px 10px 0;
  border-color: transparent red transparent transparent;
}
.right::after {
  left: -16px;
}

.txt-bubble {
  padding: 20px 30px;
  background-color: red;
  position: relative;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  .timeline::after {
    left: 31px;
  }

  .timelineWrap {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .timelineWrap::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
}
