.works {
  background-color: white;
  h1 {
    text-align: center;
    color: black;
  }
  h2 {
    color: black;
  }
}

.work-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 80px;
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.headerWork {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #0cc9a9;
    font-size: 30px;
    margin-top: 50px;
  }
}
.cardOne {
  height: 120vh;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
  margin-top: 20px;
}

.imageContainerOne {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 30vh;
}

.workContainerOne {
  margin-top: 40px;

  h2 {
    font-size: 25px;
  }
}

.workImages {
  width: 100%;
  height: 100%;
}
.WorkDescription {
  display: flex;
  height: 60vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  p {
    padding: 20px;
    font-size: 20px;
  }
}

.workButton {
}
.workButtonOne,
.workButtonTwo,
.workButtonThree {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  a {
    font-size: 26px;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 750px) {
}
@media screen and (min-width: 1500px) {
  .workContainerOne {
    font-size: 30px;
    h2 {
      font-size: 30px;
    }
  }

  .WorkDescription {
    p {
      padding: 20px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .headerWork {
    h1 {
      color: #0cc9a9;
      font-size: 40px;
    }
  }
}

@media screen and (min-width: 2000px) {
  .WorkDescription {
    p {
      padding: 30px;
      font-size: 30px;
      line-height: 70px;
    }
  }
  .workContainerOne {
    font-size: 40px;
    h2 {
      font-size: 40px;
    }
  }
  .cardOne {
    height: 120vh;
  }
  .WorkDescription {
    height: 80vh;
  }
}
