/*CUSTOM SCROLL BAR*/

a {
  text-decoration: none;
  transition: 0.5s;
  outline: none !important;
}

p {
  letter-spacing: 0.08em;
  line-height: 1.8em;
}

/*FOOTER*/
.footer {
  background-color: rgb(178, 221, 174);
  width: 100%;
  display: flex;
  align-items: top;
  padding: 5rem 0;
  h3 {
    color: #0cc9ab;
    font-size: 30px;
  }
  span {
    color: white;
  }
}

.footer section {
  padding: 1rem 2rem;
  width: 100%;
  vertical-align: top;
}

.footer section a {
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 15px 0;
  font-size: 20px;
  line-height: 20px;
}

.footer section a:hover {
  color: #0cc9a9;
}

.footer section:nth-child(2) a:before,
.footer section:nth-child(3) a:before {
  content: "";
  margin-right: 5px;
}

.footer section a span {
  margin: 0 10px;
}

.sub-footer {
  background-color: #142136;
  text-align: Center;
  color: white;
  padding: 1rem 0;
}

@media (min-width: 1200px) {
  footer {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 25px;
    }
  }
  .footer section a {
    font-size: 25px;
  }

  .sub-footer {
    font-size: 25px;
  }
}

@media (max-width: 1020px) {
  .footer {
    display: block;
  }
  .footer section {
    padding: 1rem 1rem;
  }
  .footer section:nth-child(2),
  .footer section:nth-child(3) {
    width: 49%;
    display: inline-block;
  }
}
