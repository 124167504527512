.aboutMe {
  margin-top: 80px;
}

.aboutMeWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.about-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutMeImg {
  width: 100%;
  border: 10px;
}
.aboutMeImgText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutName {
  padding: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background-color: rgba(92, 240, 134, 0.413);

  span {
    color: #0cc9a9;
    font-size: 25px;
    font-weight: bold;
  }
}
.myName {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.ojk {
  font-size: 25px;
}
.description {
  margin-top: 20px;
  font-size: 20px;
}

.aboutMeImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 500px) {
  .aboutMeImgBox {
  }
  .aboutMeImg {
  }
}
@media screen and (min-width: 1000px) {
  .aboutMeImgBox {
    width: 50%;
  }
  .aboutMeImg {
    height: 60vh;
  }
}

@media screen and (min-width: 1500px) {
  .aboutMeWrapper {
  }

  .aboutMeImgBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50vh;
  }
  .aboutMeImg {
    width: 100%;
    width: 100%;
  }

  .aboutName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    p {
      padding: 20px;
    }
  }
  .aboutMeImg {
    width: 100%;
  }
  .aboutMeWrapper {
    flex-direction: row;
    padding: 0;
  }

  .aboutMeImgText {
    width: 40%;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 50px;
  }
  .description {
    font-size: 25px;
    line-height: 40px;
  }
  .ojk {
    font-size: 35px;
    span {
      font-size: 35px;
    }
  }
}
