@import "timeline";
@import "aboutMe";
@import "works";
@import "resume";
@import "nav";
@import "profile";
@import "button";
@import "footer";
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,300;0,400;0,500;1,100;1,200;1,300;1,400&family=Inconsolata:wght@300&display=swap");
:root {
  --lite: #f8f9fa;
}
body {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

* {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
}

button {
  background-color: #0cc9ab;
  color: white;
}

span,
p {
  color: rgb(17, 8, 8);
  font-family: "Exo", sans-serif;
  font-weight: 400;
  line-height: 27px;
}

h1,
h2,
h3 {
  font-family: "Inconsolata", monospace;
  color: rgb(0, 0, 0);
}

@media screen and (min-width: 900px) {
  .gridMain {
    display: grid;
    padding-left: 100px;
  }
}
