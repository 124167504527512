.profileContainer {
  border: 1px solid black;
  height: 140vh;
  background-image: linear-gradient(
      rgba(108, 169, 124, 0.111),
      rgba(0, 0, 0, 0.353)
    ),
    url(../../images/profile-black.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(255, 240, 240, 0.25) 0px 54px 55px,
    rgba(255, 255, 255, 0.12) 0px -12px 30px,
    rgba(255, 255, 255, 0.12) 0px 4px 6px,
    rgba(255, 255, 255, 0.17) 0px 12px 13px,
    rgba(255, 253, 253, 0.09) 0px -3px 5px;
  margin-top: 50px;
}

.firstHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: #0cc9ab;
    text-align: center;
    width: 60%;
    margin-top: 40px;
    line-height: 40px;
  }
}
.headerProfile {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  h1 {
    text-align: center;
    color: white;
    font-size: 32px;
    line-height: 32px;
  }
  .utvikler {
    color: #0cc9a9;
    font-size: 34px;
    line-height: 32px;

    margin-left: 30px;
    max-width: 50%;
  }
}
.profileText {
  display: flex;
  justify-content: center;

  p {
    color: #0cc9ab;
    font-size: 20px;
    padding: 5%;
    margin-left: 10px;
    line-height: 50px;
    max-width: 100%;
  }
}
.imgProfileBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
}
.profileImg {
  max-width: 300px;
  height: 300px;
}

.imgProfileBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1000px) {
  .firstHeader {
    margin-top: 0;
    h1 {
      margin-top: 70px;
    }
  }
  .profileWrapper {
    flex-direction: row;
    margin-top: 30vh;
    margin-top: 15vh;
  }
  .profileContainer {
    height: 100vh;
  }
  .headerProfile {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    h1 {
      text-align: center;
      color: white;
      font-size: 52px;
    }
  }
  .headerProfile {
    h1,
    span {
      font-size: 42px;
    }
  }
  .profileText {
    p {
      font-size: 20px;
    }
  }
  .profileImg {
    max-width: 700px;
    height: 40vh;
  }

  .profileContainer {
    height: 100vh;
  }
  .imgProfileBox {
    width: 50%;
    padding: 20px;
  }
  .alignHeaderText {
  }
}

@media screen and (min-width: 1200px) {
  .firstHeader {
    h1 {
      font-size: 35px;
    }
  }
  .alignHeaderText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profileText {
    width: 50%;
    line-height: 25px;
    p {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1800px) {
  .profileText {
    width: 50%;

    p {
      font-size: 25px;
      line-height: 70px;
    }
  }

  .headerProfile {
    h1,
    .utvikler {
      font-size: 50px;
    }
  }
}
